import Swiper, { Autoplay } from 'swiper';
import 'swiper/css';

const selectors = {
  sliderWrapper: '[data-notice-bar]',
};

class NoticeBar extends HTMLElement {
  constructor() {
    super();

    document.addEventListener('DOMContentLoaded', () => {
      this.sliderWrapper = this.querySelector(selectors.sliderWrapper);
      this.render();
    });
  }

  render() {
    // Init the carousel on load.
    this.initSlider();

    // Subsequently, on resize, we need to check if we need a Swiper object checking the break point.
    // If not, it will get destroyed.
    this.registerEventListeners();
  }

  initSlider() {
    this.carousel = new Swiper(selectors.sliderWrapper, {
      modules: [Autoplay],
      lazy: true,
      slidesPerView: 1,
      watchOverflow: true,
      freeMode: true,
      autoplay: {
        delay: 5000,
      },
    });
  }

  registerEventListeners() {
    window.addEventListener('resize', () => {
      this.carousel.update();
    });
  }
}

customElements.define('notice-bar', NoticeBar);
